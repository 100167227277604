import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';
import {useSiteMetadata} from '../utils/use-site-metadata';

export default function NotFound() {
  const {title} = useSiteMetadata();

  return (
    <Layout variant="small">
      <Helmet>
        <title>Not Found — {title}</title>
      </Helmet>

      <div className="error">
        <h1 className="error__title">Page not found</h1>
        <div className="error__content">
          <p>The page you were looking for doesn’t exist. Maybe it moved?</p>
        </div>
      </div>
    </Layout>
  );
}
